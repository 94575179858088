.App {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  justify-content: center;
  min-height: 100vh;
  position: relative;
  text-align: center;
}

.MuiFilledInput-root {
  border-radius: 0 !important;
}

.MuiButton-root {
  border-radius: 0 !important;
  box-shadow: none !important;
}
